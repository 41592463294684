import { Add } from "@mui/icons-material";
import { Button, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import apis from "../../apis";
import MainCard from "../../components/MainCard";
import PageTitle from "../../components/PageTitle";
import Popup from "../../components/Popup";
import { DIALOG_TYPE, INITIAL_PAGING, PAGE_TYPE } from "../../constants";
import useSearchParams from "../../hooks/useSearchParams";
import DiscountDialog from "./DiscountDialog";
import DiscountList from "./DiscountList";
import { DISCOUNT_STATUS } from "../../constants/discount";
import { isActiveDiscount } from "../../services/discount";

const INIT_DISCOUNT = {
  name: "",
  code: "",
  status: DISCOUNT_STATUS.ACTIVE,
  condition: {},
  value: {},
  appliedObject: {},
};

const Discount = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [discounts, setDiscounts] = useState([]);
  const [discount, setDiscount] = useState(INIT_DISCOUNT);
  const [openDiscountDialog, setOpenDiscountDialog] = useState(false);
  const [reload, setReload] = useState(false);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogType, setDialogType] = useState();
  const [provinces, setProvinces] = useState([]);
  const [regions, setRegions] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [stores, setStores] = useState([]);
  const [products, setProducts] = useState([]);
  const [openDeleteDiscountPopup, setOpenDeleteDiscountPopup] = useState(false);

  const { addParams } = useSearchParams();

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const handleOpenUserDialog = (dialogType, currDiscount = INIT_DISCOUNT) => {
    setDialogType(dialogType);
    setDiscount(currDiscount);
    setOpenDiscountDialog(true);
  };

  const handleCloseDiscountDialog = () => {
    setOpenDiscountDialog(false);
    setDiscount(INIT_DISCOUNT);
  };

  const handleOpenDeleteDiscountPopup = (currDiscount) => {
    setDiscount(currDiscount);
    setOpenDeleteDiscountPopup(true);
  };

  const handleCloseDeleteDiscountPopup = () => {
    setOpenDeleteDiscountPopup(false);
  };

  const handleReload = () => {
    setReload((prev) => !prev);
  };

  const fetchDiscounts = async ({ page }) => {
    setLoading(true);
    try {
      const condition = {
        page: page || paging.page,
        limit: paging.limit,
      };
      const { result } = await apis.discount.getDiscounts(condition);
      setDiscounts(result.items);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const fetchRoles = async () => {
    try {
      const res = await apis.role.getRoles({ pageType: PAGE_TYPE.USER });
      const { result = [] } = res;
      setRoles(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchProvinces = async () => {
    try {
      const res = await apis.address.getVnUnits();
      const { result = [] } = res;
      setProvinces(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchRegions = async () => {
    try {
      const res = await apis.region.getRegions();
      const { result = [] } = res;
      setRegions(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchDistributors = async () => {
    try {
      const res = await apis.user.getUsers({
        pageType: PAGE_TYPE.DISTRIBUTOR,
      });
      const { result = [] } = res;
      setDistributors(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchStores = async () => {
    try {
      const res = await apis.store.getStores();
      const { result = [] } = res;
      setStores(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchProducts = async () => {
    try {
      const res = await apis.product.getProducts();
      const { result = [] } = res;
      setProducts(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const handleDeleteDiscount = async () => {
    try {
      const res = await apis.discount.updateDiscount(discount.id, {
        status: isActiveDiscount(discount)
          ? DISCOUNT_STATUS.INACTIVE
          : DISCOUNT_STATUS.ACTIVE,
      });
      if (!res) throw new Error();
      toast.success(t("updateDiscountSuccess"));
      handleReload();
    } catch (error) {
      toast.error(t(error.message));
    }
    setDiscount(INIT_DISCOUNT);
  };

  useEffect(() => {
    fetchRoles();
    fetchProvinces();
    fetchRegions();
    fetchDistributors();
    fetchStores();
    fetchProducts();
  }, []);

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const { page = INITIAL_PAGING.page } = searchParams;
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    fetchDiscounts({ page: parseInt(page, 10) });
  }, [location.search, reload]);

  return (
    <>
      <PageTitle title={t("discountManagement")} />
      <MainCard>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={6} md={6} lg={4}></Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}></Grid>
          <Grid item xs={12} sm={3} md={3} lg={6}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => handleOpenUserDialog(DIALOG_TYPE.CREATE)}
              >
                {t("createDiscount")}
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <DiscountList
          loading={loading}
          paging={paging}
          handleChangePage={handleChangePage}
          discounts={discounts}
          handleOpenDiscountDialog={handleOpenUserDialog}
          handleOpenActivateDiscountPopup={handleOpenDeleteDiscountPopup}
        />
      </MainCard>
      <DiscountDialog
        open={openDiscountDialog}
        onClose={handleCloseDiscountDialog}
        initDiscount={discount}
        handleReload={handleReload}
        provinces={provinces}
        regions={regions}
        distributors={distributors}
        stores={stores}
        products={products}
        dialogType={dialogType}
      />
      <Popup
        open={openDeleteDiscountPopup}
        onClose={handleCloseDeleteDiscountPopup}
        onOk={handleDeleteDiscount}
        okMessage={t("accept")}
        content={t(
          `${
            isActiveDiscount(discount)
              ? "areYouSureDeactivateDiscount"
              : "areYouSureActivateDiscount"
          }`,
          {
            discount: discount.name,
          }
        )}
        title={t(
          `${isActiveDiscount(discount) ? "deactivate" : "activate"}Discount`
        )}
      />
    </>
  );
};

export default Discount;
