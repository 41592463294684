import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import MainCard from "../../components/MainCard";
import PageTitle from "../../components/PageTitle";

import { ALL, INITIAL_PAGING, PAGE_TYPE, STATUS } from "../../constants";
import apis from "../../apis";
import StoreList from "./StoreList";
import useSearchParams from "../../hooks/useSearchParams";
import { DownloadOutlined, Search } from "@mui/icons-material";
import debounce from "../../utils/debounce";
import { downloadFile } from "../../utils/download";

const INIT_FILTER = {
  search: "",
  region: ALL,
  saleId: ALL,
  status: ALL,
};

const Store = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const accessToken = useSelector((state) => state.auth.accessToken);

  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [distributors, setDistributors] = useState([]);
  const [filter, setFilter] = useState(INIT_FILTER);
  const [regions, setRegions] = useState([]);
  const [sales, setSales] = useState([]);
  const [downloading, setDownloading] = useState(false);

  const { addParams } = useSearchParams();

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const onChangeSearch = (value) => {
    setPaging((prev) => ({ ...prev, page: INITIAL_PAGING.page }));
    addParams({ search: value, page: INITIAL_PAGING.page });
  };

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, search: value }));
    debounce(onChangeSearch, 1000)(value);
  };

  const handleChangeRegion = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, region: value }));
    setPaging((prev) => ({ ...prev, page: INITIAL_PAGING.page }));
    addParams({
      region: value === ALL ? "" : value,
      page: INITIAL_PAGING.page,
    });
  };

  const handleChangeSaleId = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, saleId: value }));
    setPaging((prev) => ({ ...prev, page: INITIAL_PAGING.page }));
    addParams({
      saleId: value === ALL ? "" : value,
      page: INITIAL_PAGING.page,
    });
  };

  const handleChangeStatus = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, status: value }));
    setPaging((prev) => ({ ...prev, page: INITIAL_PAGING.page }));
    addParams({
      status: value === ALL ? "" : value,
      page: INITIAL_PAGING.page,
    });
  };

  const getStatusCondition = (status) =>
    parseInt(status, 10) === STATUS.ACTIVE ? true : false;

  const handleDownload = async () => {
    setDownloading(true);
    try {
      const searchParams = queryString.parse(location.search);
      const {
        search = INIT_FILTER.search,
        region = INIT_FILTER.region,
        saleId = INIT_FILTER.saleId,
        status = INIT_FILTER.status,
      } = searchParams;

      await downloadFile({
        url: "/stores/download",
        params: {
          search: search || undefined,
          region: region === ALL ? undefined : region,
          saleId: saleId === ALL ? undefined : saleId,
          isVerified: status === ALL ? undefined : getStatusCondition(status),
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        fileName: `stores_${dayjs().unix()}.xlsx`,
      });
    } catch (error) {
      toast.error(t(error.message));
    }

    setDownloading(false);
  };

  const fetchStores = async (newFilter) => {
    setLoading(true);
    try {
      const condition = {
        limit: paging.limit,
        page: newFilter.page,
        search: newFilter.search || undefined,
        region: newFilter.region === ALL ? undefined : newFilter.region,
        saleId: newFilter.saleId === ALL ? undefined : newFilter.saleId,
        isVerified:
          newFilter.status === ALL
            ? undefined
            : getStatusCondition(newFilter.status),
      };

      const { result } = await apis.store.getStores(condition);
      setStores(result.stores);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const fetchDistributors = async () => {
    try {
      const condition = {
        pageType: PAGE_TYPE.DISTRIBUTOR,
      };

      const { result } = await apis.user.getUsers(condition);
      setDistributors(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchSales = async () => {
    try {
      const condition = {
        pageType: PAGE_TYPE.SALE,
      };

      const { result } = await apis.user.getUsers(condition);
      setSales(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchRegions = async () => {
    try {
      const { result } = await apis.region.getRegions();
      setRegions(result.map((r) => r.name));
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const {
      page = INITIAL_PAGING.page,
      search = INIT_FILTER.search,
      region = INIT_FILTER.region,
      saleId = INIT_FILTER.saleId,
      status = INIT_FILTER.status,
    } = searchParams;
    setFilter({ search, region, saleId, status });
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    fetchStores({ search, region, saleId, status, page: parseInt(page, 10) });
  }, [location.search]);

  useEffect(() => {
    fetchDistributors();
    fetchSales();
    fetchRegions();
  }, []);

  return (
    <>
      <PageTitle title={t("store")} />
      <MainCard>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <TextField
              value={filter.search}
              size="small"
              fullWidth
              placeholder={t("storeSearch")}
              onChange={handleChangeSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("region")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.region}
                onChange={handleChangeRegion}
                size="small"
                label={t("region")}
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                {regions.map((region) => (
                  <MenuItem key={region} value={region}>
                    {t("region")} {region}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{t("sale")}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.saleId}
                onChange={handleChangeSaleId}
                size="small"
                label={t("sale")}
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                {sales.map((sale) => (
                  <MenuItem key={sale.id} value={sale.id}>
                    {`${sale.name} - ${sale.phoneNumber}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("status")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.status}
                onChange={handleChangeStatus}
                size="small"
                label={t("status")}
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                <MenuItem value={STATUS.ACTIVE}>{t("verified")}</MenuItem>
                <MenuItem value={STATUS.INACTIVE}>{t("unverified")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={2}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <LoadingButton
                loading={downloading}
                loadingPosition="start"
                variant="outlined"
                startIcon={<DownloadOutlined />}
                disabled={!stores.length}
                onClick={handleDownload}
              >
                {t("download")}
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
        <StoreList
          loading={loading}
          paging={paging}
          handleChangePage={handleChangePage}
          stores={stores}
          distributors={distributors}
        />
      </MainCard>
    </>
  );
};

export default Store;
