import { Edit, LockOpenOutlined, LockOutlined } from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";

import Table from "../../components/Table";
import { DATE_TIME_FORMAT, DIALOG_TYPE } from "../../constants";
import {
  APPLIED_OBJECT_MAPPING,
  DISCOUNT_STATUS,
} from "../../constants/discount";
import { isActiveDiscount } from "../../services/discount";
import { COLOR } from "../../styles/color";

const getDiscountConditionText = (discount = {}) => {
  const { condition } = discount;
  if (!condition) return "";
  const { type, amount, product } = condition;
  if (type === "PRODUCT") {
    return `${amount} ${product.name}`;
  }
  if (type === "MONEY") {
    return `${amount?.toLocaleString()} VNĐ`;
  }
  return "";
};

const getDiscountValueText = (discount = {}) => {
  const { value } = discount;
  if (!value) return "";
  const { type, amount, product } = value;
  if (type === "PRODUCT") {
    return `${amount} ${product.name}`;
  }
  if (type === "MONEY") {
    return `${amount?.toLocaleString()} VNĐ`;
  }
  return "";
};

const getDiscountAppliedObject = (discount = {}) => {
  const { appliedObject } = discount;
  if (!appliedObject) return "";
  const { type, value } = appliedObject;
  if (type === "ALL") return "Tất cả cửa hàng";
  return `${APPLIED_OBJECT_MAPPING[type] || ""} ${value
    ?.map((item) => item.name || "")
    .join(", ")}`;
};

const DiscountList = ({
  loading,
  paging,
  handleChangePage,
  discounts,
  handleOpenDiscountDialog,
  handleOpenActivateDiscountPopup,
}) => {
  const { t } = useTranslation();

  const renderDiscountStatus = (discount) => {
    const { status, startDate, endDate } = discount;
    if (status === DISCOUNT_STATUS.INACTIVE) {
      return (
        <Typography sx={{ color: COLOR.error.base }}>
          {t("inactive")}
        </Typography>
      );
    }
    if (startDate && moment(startDate).isAfter(moment())) {
      return (
        <Typography sx={{ color: COLOR.secondary.base }}>
          {t("notActiveYet")}
        </Typography>
      );
    }
    if (endDate && moment(endDate).isBefore(moment())) {
      return (
        <Typography sx={{ color: COLOR.secondary.base }}>
          {t("expired")}
        </Typography>
      );
    }
    if (status === "ACTIVE") {
      return (
        <Typography sx={{ color: COLOR.success.base }}>
          {t("active")}
        </Typography>
      );
    }
    return "--";
  };
  const columns = [
    {
      title: t("name"),
      align: "left",
      field: "name",
    },
    {
      title: t("condition"),
      align: "left",
      render: (row) => <Typography>{getDiscountConditionText(row)}</Typography>,
    },
    {
      title: t("discountValue"),
      align: "left",
      render: (row) => <Typography>{getDiscountValueText(row)}</Typography>,
    },
    {
      title: t("appliedObject"),
      align: "left",
      render: (row) => <Typography>{getDiscountAppliedObject(row)}</Typography>,
    },
    {
      title: t("startDate"),
      align: "left",
      render: (row) => (
        <Typography>
          {row.startDate
            ? moment(row.startDate).format(DATE_TIME_FORMAT)
            : "--"}
        </Typography>
      ),
    },
    {
      title: t("endDate"),
      align: "left",
      render: (row) => (
        <Typography>
          {row.endDate ? moment(row.endDate).format(DATE_TIME_FORMAT) : "--"}
        </Typography>
      ),
    },
    {
      title: t("status"),
      align: "left",
      render: (row) => renderDiscountStatus(row),
    },
    {
      title: "...",
      align: "center",
      render: (row) => (
        <>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip title={t("edit")}>
              <span>
                <IconButton
                  onClick={() =>
                    handleOpenDiscountDialog(DIALOG_TYPE.UPDATE, row)
                  }
                  color="edit"
                >
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              title={
                isActiveDiscount(row)
                  ? t("deactivateDiscount")
                  : t("activateDiscount")
              }
            >
              <span>
                <IconButton
                  onClick={() => handleOpenActivateDiscountPopup(row)}
                >
                  {isActiveDiscount(row) ? (
                    <LockOutlined color="error" />
                  ) : (
                    <LockOpenOutlined color="info" />
                  )}
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        data={discounts}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
      />
    </>
  );
};

export default DiscountList;
