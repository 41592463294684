const WALLET_TYPE = {
  PAYMENT: "PAYMENT",
  FREIGHT_COMPENSATION: "FREIGHT_COMPENSATION",
  BONUS: "BONUS",
  COMMODITY_COMPENSATION: "COMMODITY_COMPENSATION",
};

const TRANSACTION_STATUS = {
  PENDING: "PENDING",
  FAILED: "FAILED",
  CANCELED: "CANCELED",
  COMPLETED: "COMPLETED",
};

const TRANSACTION_TYPE = {
  PAY_IN: "PAY_IN",
  PAY_OUT: "PAY_OUT",
  REFUND: "REFUND",
};

module.exports = { WALLET_TYPE, TRANSACTION_STATUS, TRANSACTION_TYPE };
