const A_WEEK = 7 * 24 * 60 * 60 * 1000;
const PAGINATION_LIMIT = 10;
const INITIAL_PAGING = { page: 1, total: 0, limit: PAGINATION_LIMIT };
const ALL = "ALL";
const LANGUAGE = { VN: "vi", EN: "en", CN: "cn" };
const SERVICE = {
  VELA_AUTH: "VELA_AUTH",
  VELA_API: "VELA_API",
  VELA_UPLOAD: "VELA_UPLOAD",
};

const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";

const ROLE_TYPE = {
  MINI_APP: "MINI_APP",
  ADMIN: "ADMIN",
};
const PERMISSION_TYPE = {
  MENU: "MENU",
  API: "API",
};

const HTTP_METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

const DIALOG_TYPE = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
};

const PAGE_TYPE = {
  USER: "USER",
  DISTRIBUTOR: "DISTRIBUTOR",
  SUPERVISOR: "SUPERVISOR",
  TIMESHEET: "TIMESHEET",
  SALE: "SALE",
};

const STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
};

const MINI_APP_ROLE_KEY = {
  CUSTOMER: "CUSTOMER",
  SALE: "SALE",
  ASM: "ASM",
  SUPERVISOR: "SUPERVISOR",
  DISTRIBUTOR: "DISTRIBUTOR",
  BUSINESS_DIRECTOR: "BUSINESS_DIRECTOR",
};

export {
  A_WEEK,
  PAGINATION_LIMIT,
  INITIAL_PAGING,
  ALL,
  LANGUAGE,
  SERVICE,
  ROLE_TYPE,
  PERMISSION_TYPE,
  HTTP_METHOD,
  DIALOG_TYPE,
  PAGE_TYPE,
  DATE_TIME_FORMAT,
  STATUS,
  MINI_APP_ROLE_KEY,
};
