import Info from "./Info";
import Kpi from "./Kpi";
import Rate from "./Rate";
import Wallet from "./Wallet";
import ExceptionRate from "./ExceptionRate";

const tabs = [
  {
    name: "basicInfo",
    value: "basic-info",
    component: <Info />,
  },
  {
    name: "kpi",
    value: "kpi",
    component: <Kpi />,
  },
  {
    name: "rate",
    value: "rate",
    component: <Rate />,
  },
  {
    name: "exception",
    value: "exception-rate",
    component: <ExceptionRate />,
  },
  {
    name: "wallet",
    value: "wallet",
    component: <Wallet />,
  },
];

export default tabs;
