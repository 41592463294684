import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Button, Grid, InputAdornment, Stack, TextField } from "@mui/material";
import { Add, DownloadOutlined, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import apis from "../../apis";
import { QR_TYPE } from "../../constants/qr";
import DistributorList from "./DistributorList";
import PageTitle from "../../components/PageTitle";
import MainCard from "../../components/MainCard";
import QrDialog from "../../components/QrDialog";
import { INITIAL_PAGING, PAGE_TYPE } from "../../constants";
import debounce from "../../utils/debounce";
import useSearchParams from "../../hooks/useSearchParams";
import Popup from "../../components/Popup";
import { downloadFile } from "../../utils/download";

const INIT_FILTER = {
  search: "",
};

const Distributor = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const accessToken = useSelector((state) => state.auth.accessToken);

  const [filter, setFilter] = useState(INIT_FILTER);
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [reload, setReload] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openQrDialog, setOpenQrDialog] = useState(false);
  const [qrType, setQrType] = useState(null);
  const [openChangeStatusPopup, setOpenChangeStatusPopup] = useState(false);
  const [distributor, setDistributor] = useState({});
  const [downloading, setDownloading] = useState(false);

  const { addParams } = useSearchParams();

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const onChangeSearch = (value) => {
    setPaging((prev) => ({ ...prev, page: INITIAL_PAGING.page }));
    addParams({ search: value, page: INITIAL_PAGING.page });
  };

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, search: value }));
    debounce(onChangeSearch, 1000)(value);
  };

  const handleOpenQrDialog = () => {
    setQrType(QR_TYPE.CREATE_DISTRIBUTOR);
    setOpenQrDialog(true);
  };

  const handleCloseQrDialog = () => {
    setOpenQrDialog(false);
    setQrType(null);
  };

  const handleOpenChangeStatusPopup = (currDistributor) => {
    setDistributor(currDistributor);
    setOpenChangeStatusPopup(true);
  };

  const handleCloseChangeStatusPopup = () => {
    setOpenChangeStatusPopup(false);
  };

  const handleReload = () => {
    setReload((prev) => !prev);
  };

  const handleChangeStatus = async () => {
    try {
      const res = await apis.user.changeStatus(
        distributor.id,
        !distributor.active
      );
      if (!res) throw new Error();
      toast.success(
        distributor.active ? t("lockAccountSuccess") : t("unlockAccountSuccess")
      );
      handleReload();
    } catch (error) {
      toast.error(t(error.message));
    }
    setDistributor({});
  };

  const handleDownload = async () => {
    setDownloading(true);
    try {
      const searchParams = queryString.parse(location.search);
      const { search = INIT_FILTER.search, roleId = INIT_FILTER.roleId } =
        searchParams;

      await downloadFile({
        url: "/users/download",
        params: {
          search: search || undefined,
          pageType: roleId ? undefined : PAGE_TYPE.DISTRIBUTOR,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        fileName: `distributors_${dayjs().unix()}.xlsx`,
      });
    } catch (error) {
      toast.error(t(error.message));
    }

    setDownloading(false);
  };

  const fetchUsers = async (newFilter) => {
    setLoading(true);
    try {
      const condition = {
        search: newFilter.search || undefined,
        limit: paging.limit,
        page: newFilter.page,
        pageType: PAGE_TYPE.DISTRIBUTOR,
      };

      const { result } = await apis.user.getUsers(condition);
      setUsers(result.users);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const { search = INIT_FILTER.search, page = INITIAL_PAGING.page } =
      searchParams;
    setFilter({ search });
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    fetchUsers({ search, page: parseInt(page, 10) });
  }, [location.search, reload]);
  return (
    <>
      <PageTitle title={t("distributor")} />
      <MainCard>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <TextField
              value={filter.search}
              size="small"
              fullWidth
              placeholder={t("userSearch")}
              onChange={handleChangeSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}></Grid>
          <Grid item xs={12} sm={3} md={3} lg={6}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={handleOpenQrDialog}
              >
                {t("addDistributor")}
              </Button>
              <LoadingButton
                loading={downloading}
                loadingPosition="start"
                variant="outlined"
                startIcon={<DownloadOutlined />}
                disabled={!users.length}
                onClick={handleDownload}
              >
                {t("download")}
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
        <DistributorList
          loading={loading}
          paging={paging}
          handleChangePage={handleChangePage}
          distributors={users}
          handleOpenChangeStatusPopup={handleOpenChangeStatusPopup}
        />
      </MainCard>
      <QrDialog
        open={openQrDialog}
        handleClose={handleCloseQrDialog}
        qrType={qrType}
      />
      <Popup
        open={openChangeStatusPopup}
        onClose={handleCloseChangeStatusPopup}
        onOk={handleChangeStatus}
        okMessage={t("accept")}
        content={
          distributor.active
            ? t("areYouSureLockAccount", {
                name: distributor.name,
                phoneNumber: distributor.phoneNumber,
              })
            : t("areYouSureUnlockAccount", {
                name: distributor.name,
                phoneNumber: distributor.phoneNumber,
              })
        }
        title={distributor.active ? t("lockAccount") : t("unlockAccount")}
      />
    </>
  );
};

export default Distributor;
