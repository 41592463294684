import ROUTE from "../constants/route";
import Timesheet from "../pages/Timesheet";
import Dashboard from "../pages/Dashboard";
import Distributor from "../pages/Distributor";
import Login from "../pages/Login";
import Permission from "../pages/Permission";
import Role from "../pages/Role";
import Store from "../pages/Store";
import User from "../pages/User";
import TimesheetDetail from "../pages/TimesheetDetail";
import Discount from "../pages/Discount";
import Order from "../pages/Order";
import DistributorDetail from "../pages/DistributorDetail";
import Product from "../pages/Product";
import Category from "../pages/Category";
import Transaction from "../pages/Transaction";
import PaymentAccount from "../pages/PaymentAccount";

const appRoutes = [
  {
    path: ROUTE.LOGIN,
    component: Login,
    restricted: true,
    isPrivate: false,
  },
  {
    path: ROUTE.DASHBOARD,
    component: Dashboard,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.USER,
    component: User,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.PERMISSION,
    component: Permission,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.ROLE,
    component: Role,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.DISTRIBUTOR,
    component: Distributor,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.STORE,
    component: Store,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.TIMESHEET,
    component: Timesheet,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.TIMESHEET_DETAIL,
    component: TimesheetDetail,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.DISCOUNT,
    component: Discount,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.ORDER,
    component: Order,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.DISTRIBUTOR_DETAIL,
    component: DistributorDetail,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.PRODUCT,
    component: Product,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.CATEGORY,
    component: Category,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.TRANSACTION_NEW,
    component: Transaction,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTE.PAYMENT_ACCOUNT,
    component: PaymentAccount,
    restricted: false,
    isPrivate: true,
  },
];

export default appRoutes;
