import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import Table from "../../components/Table";
import { COLOR } from "../../styles/color";
import { ORDER_STATUS } from "../../constants/order";
import { renderStoreAddress } from "../../services/store";
import ImagePreview from "../../components/ImagePreview";

const OrderList = ({
  loading,
  paging,
  handleChangePage,
  orders,
  users = [],
}) => {
  const { t } = useTranslation();

  const findDistributor = (staffId) => {
    return users.find((user) => user.staffIds?.includes(staffId));
  };

  const columns = [
    {
      title: t("store"),
      align: "left",
      render: (row) => row.store?.name || "--",
    },
    {
      title: t("salePerson"),
      align: "left",
      render: (row) => row.creator?.name || "--",
    },
    {
      title: t("distributor"),
      align: "left",
      render: (row) => findDistributor(row.creator?.id)?.name || "--",
    },
    {
      title: t("address"),
      align: "left",
      render: (row) => (
        <Typography>{renderStoreAddress(row.store) || "--"}</Typography>
      ),
    },
    {
      title: t("orderItems"),
      align: "left",
      render: (row) => (
        <>
          {row.items?.map((item) => (
            <Typography>{`${item.quantity || 0} ${item.name}`}</Typography>
          ))}
        </>
      ),
    },
    {
      title: t("orderPrice"),
      align: "right",
      render: (row) => row.orderPrice?.toLocaleString() || 0,
    },
    {
      title: t("discountPrice"),
      align: "right",
      render: (row) => row.discountPrice?.toLocaleString() || "--",
    },
    {
      title: t("discountItems"),
      align: "left",
      render: (row) => (
        <Typography>
          {row.discountItems
            ?.map((item) => `${item.quantity || 0} ${item.name}`)
            ?.join(", ") || "--"}
        </Typography>
      ),
    },
    {
      title: t("totalPrice"),
      align: "right",
      render: (row) => row.totalPrice?.toLocaleString() || 0,
    },
    {
      title: t("status"),
      align: "left",
      render: (row) => (
        <>
          {row.status === ORDER_STATUS.COMPLETED && (
            <Typography sx={{ color: COLOR.success.base }}>
              {t("completed")}
            </Typography>
          )}
          {row.status === ORDER_STATUS.IN_PROGRESS && (
            <Typography sx={{ color: COLOR.info.base }}>
              {t("in_progress")}
            </Typography>
          )}
          {row.status === ORDER_STATUS.AWAITING_CONFIRMATION && (
            <Typography sx={{ color: COLOR.warning.base }}>
              {t("awaiting_confirmation")}
            </Typography>
          )}
          {row.status === ORDER_STATUS.CANCEL && (
            <Typography sx={{ color: COLOR.error.base }}>
              {t("canceled")}
            </Typography>
          )}
        </>
      ),
    },
    {
      title: t("createdAt"),
      align: "left",
      render: (row) => dayjs(row.createdAt).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: t("image"),
      align: "center",
      render: (row) => (
        <ImagePreview
          galleryID="custom-image-gallery-12345"
          images={[
            ...(row.signatureImage
              ? [
                  {
                    url: row.signatureImage,
                  },
                ]
              : []),
            ...(row.images?.[0]
              ? [
                  {
                    url: row.images?.[0],
                  },
                ]
              : []),
          ]}
        />
      ),
    },
  ];

  return (
    <>
      <Table
        data={orders}
        columns={columns}
        total={paging.total}
        page={paging.page}
        limit={paging.limit}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
      />
    </>
  );
};

export default OrderList;
