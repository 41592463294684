import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, DialogActions, DialogContent, Divider } from "@mui/material";

import { QR_TYPE } from "../../constants/qr";
import Dialog from "../Dialog";
import AsmConfig from "./AsmConfig";
import DisplayQr from "./DisplayQr";
import SupervisorConfig from "./SupervisorConfig";
import DistributorConfig from "./DistributorConfig";

const INIT_DATA = {
  region: "",
  distributorIds: [],
  supervisorId: "",
  name: "",
  email: "",
  phoneNumber: "",
  province: "",
  district: "",
  ward: "",
  addressDetail: "",
  citizenIdentification: "",
  cooperatedAt: null,
};

const QrDialog = ({ open, handleClose, qrType }) => {
  const { t } = useTranslation();

  const [isFetchQr, setIsFetchQr] = useState(false);
  const [data, setData] = useState(INIT_DATA);

  const handleCloseDialog = () => {
    handleClose();
    setIsFetchQr(false);
    setData(INIT_DATA);
  };

  const getQrName = () => {
    switch (qrType) {
      case QR_TYPE.CREATE_ASM:
        return t("addNewAsm");
      case QR_TYPE.CREATE_SUPERVISOR:
        return t("addNewSupervisor");
      case QR_TYPE.CREATE_DISTRIBUTOR:
        return t("addNewDistributor");
      case QR_TYPE.CREATE_BUSINESS_DIRECTOR:
        return t("addNewBusinessDirector");

      default:
        return "";
    }
  };

  const getConfig = () => {
    switch (qrType) {
      case QR_TYPE.CREATE_ASM:
        return (
          <AsmConfig
            data={data}
            setData={setData}
            setIsFetchQr={setIsFetchQr}
          />
        );
      case QR_TYPE.CREATE_SUPERVISOR:
        return (
          <SupervisorConfig
            data={data}
            setData={setData}
            setIsFetchQr={setIsFetchQr}
          />
        );

      case QR_TYPE.CREATE_DISTRIBUTOR:
        return (
          <DistributorConfig
            data={data}
            setData={setData}
            setIsFetchQr={setIsFetchQr}
          />
        );
      case QR_TYPE.CREATE_BUSINESS_DIRECTOR:
        setIsFetchQr(true);
        return <></>;
      default:
        return <></>;
    }
  };

  return (
    <Dialog
      title={getQrName()}
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        {!isFetchQr ? (
          getConfig()
        ) : (
          <DisplayQr data={data} qrType={qrType} setIsFetchQr={setIsFetchQr} />
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QrDialog;
