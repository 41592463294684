import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import QRcode from "qrcode.react";
import dayjs from "dayjs";

import apis from "../../apis";
import { QR_TYPE } from "../../constants/qr";

const QrDialog = ({ qrType, data, setIsFetchQr }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [qrValue, setQrValue] = useState();

  const getQrDescription = () => {
    switch (qrType) {
      case QR_TYPE.CREATE_ASM:
        return t("scanToRegisterAsm");
      case QR_TYPE.CREATE_SUPERVISOR:
        return t("scanToRegisterSupervisor");
      case QR_TYPE.CREATE_DISTRIBUTOR:
        return t("scanToRegisterDistributor");

      default:
        return "";
    }
  };

  const downloadQR = () => {
    const canvas = document.getElementById("my-qr");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "my-qr.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const getQr = () => {
    const {
      region,
      distributorIds,
      supervisorId,
      name,
      email,
      phoneNumber,
      province,
      district,
      ward,
      addressDetail,
      citizenIdentification,
      cooperatedAt,
    } = data;
    switch (qrType) {
      case QR_TYPE.CREATE_ASM:
        return apis.qr.createAddAsmQr({ region });
      case QR_TYPE.CREATE_SUPERVISOR:
        return apis.qr.createAddSupervisorQr({ region, distributorIds });
      case QR_TYPE.CREATE_DISTRIBUTOR:
        return apis.qr.createAddDistributorQr({
          region,
          supervisorId,
          metadata: {
            name,
            email,
            phoneNumber,
            address: {
              province,
              district,
              ward,
              addressDetail,
            },
            citizenIdentification,
            cooperatedAt: dayjs(cooperatedAt).startOf("day"),
          },
        });
      case QR_TYPE.CREATE_BUSINESS_DIRECTOR:
        return apis.qr.createAddBusinessDirectorQr();
      default:
        return;
    }
  };

  const fetchQrValue = async () => {
    setLoading(true);
    try {
      const res = await getQr();
      setQrValue(JSON.stringify(res.result));
      setLoading(false);
    } catch (error) {
      toast.error(t(error.message));
      setIsFetchQr((prev) => !prev);
    }
  };

  useEffect(() => {
    fetchQrValue();
  }, []);

  return (
    <>
      {loading ? (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          height="400px"
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Box height="400px">
          {qrType !== QR_TYPE.CREATE_BUSINESS_DIRECTOR ? (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              onClick={() => setIsFetchQr((prev) => !prev)}
            >
              <IconButton color="primary">
                <ArrowBackIos />
              </IconButton>
              <Typography color="primary" sx={{ cursor: "pointer" }}>
                {t("back")}
              </Typography>
            </Stack>
          ) : (
            <Box height="36px" />
          )}
          <Stack
            pt={2}
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <QRcode id="my-qr" value={qrValue} size={250} />
            <Box pt={2}>
              <Typography>{getQrDescription()}</Typography>
            </Box>
            <Button size="large" onClick={downloadQR}>
              {t("download")}
            </Button>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default QrDialog;
