import {
  Avatar,
  Box,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import {
  PermIdentityOutlined,
  EmailOutlined,
  LocalPhoneOutlined,
  ContactEmergency,
  LocationOn,
} from "@mui/icons-material";

import MainCard from "../../../components/MainCard";
import { useTranslation } from "react-i18next";
import { COLOR } from "../../../styles/color";

const DistributorInfo = ({ distributor }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={4}>
        <MainCard sx={{ padding: 1 }}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            mb={3}
          >
            <Avatar
              alt={distributor.name}
              src={distributor.avatar}
              sx={{ width: 100, height: 100 }}
            />
            <Box>
              <Typography fontWeight={600} fontSize="20px" align="center">
                {distributor.metadata?.name || distributor.name}
              </Typography>
              <Typography align="center">{t("distributor")}</Typography>
            </Box>
          </Stack>
          <Box mb={3}>
            <Divider />
          </Box>

          <Stack direction="row" alignItems="center" spacing={2} mb={1.5}>
            <PermIdentityOutlined color="disabled" fontSize="small" />
            <Typography fontWeight={500}>
              {distributor.metadata?.name || distributor.name}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2} mb={1.5}>
            <EmailOutlined color="disabled" fontSize="small" />
            <Typography fontWeight={500}>
              {distributor.metadata?.email || "-"}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2} mb={1.5}>
            <LocalPhoneOutlined color="disabled" fontSize="small" />
            <Typography fontWeight={500}>
              {distributor.phoneNumber || "-"}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2} mb={1.5}>
            <ContactEmergency color="disabled" fontSize="small" />
            <Typography fontWeight={500}>
              {distributor.metadata?.citizenIdentification || "-"}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <LocationOn color="disabled" fontSize="small" />
            <Typography fontWeight={500}>
              {[
                distributor.metadata?.address?.addressDetail,
                distributor.metadata?.address?.ward,
                distributor.metadata?.address?.district,
                distributor.metadata?.address?.province,
              ]
                .filter((item) => item)
                .join(", ")}
            </Typography>
          </Stack>
        </MainCard>
      </Grid>
      <Grid item sm={12} md={8}>
        <MainCard title={t("info")}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    <Typography color={COLOR.black[64]} fontSize="14px">
                      {t("region")}
                    </Typography>
                    <Typography fontWeight={500}>
                      {t("region")} {distributor.region}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography color={COLOR.black[64]} fontSize="14px">
                      {t("supervisor")}
                    </Typography>
                    <Typography fontWeight={500}>
                      {distributor.supervisor?.metadata?.name ||
                        distributor.supervisor?.name ||
                        "-"}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default DistributorInfo;
