import * as auth from "./auth";
import * as groupPermission from "./groupPermission";
import * as permission from "./permission";
import * as role from "./role";
import * as user from "./user";
import * as qr from "./qr";
import * as region from "./region";
import * as address from "./address";
import * as store from "./store";
import * as timesheet from "./timesheet";
import * as discount from "./discount";
import * as product from "./product";
import * as order from "./order";
import * as rate from "./rate";
import * as wallet from "./wallet";
import * as category from "./category";
import * as upload from "./upload";
import * as transaction from "./transaction";
import * as paymentAccount from "./paymentAccount";
import * as exceptionRate from "./exceptionRate";

const allApi = {
  auth,
  groupPermission,
  permission,
  role,
  user,
  qr,
  region,
  address,
  store,
  timesheet,
  discount,
  product,
  order,
  rate,
  wallet,
  category,
  upload,
  transaction,
  paymentAccount,
  exceptionRate,
};

export default allApi;
