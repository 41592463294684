const ROUTE = {
  DASHBOARD: "/",
  LOGIN: "/login",
  PERMISSION: "/permission",
  ROLE: "/role",
  USER: "/user",
  DISTRIBUTOR: "/distributor",
  DISTRIBUTOR_DETAIL: "/distributor/:distributorId",
  STORE: "/store",
  TIMESHEET: "/timesheet",
  TIMESHEET_DETAIL: "/timesheet/user/:userId",
  DISCOUNT: "/discount",
  ORDER: "/order",
  PRODUCT: "/product",
  CATEGORY: "/category",
  TRANSACTION_NEW: "/transaction/new",
  PAYMENT_ACCOUNT: "/payment-account",
};

export default ROUTE;
