const ORDER_STATUS = {
  AWAITING_CONFIRMATION: "AWAITING_CONFIRMATION",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  CANCEL: "CANCEL",
};

const ORDER_STATUS_MAPPING = {
  [ORDER_STATUS.AWAITING_CONFIRMATION]: "awaiting_confirmation",
  [ORDER_STATUS.IN_PROGRESS]: "in_progress",
  [ORDER_STATUS.COMPLETED]: "completed",
  [ORDER_STATUS.CANCEL]: "canceled",
};

export { ORDER_STATUS, ORDER_STATUS_MAPPING };
